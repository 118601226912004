import React, { useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import { Seo } from '../components/base'
import { createPagePath, marketPath } from '../utils/'
import { LayoutBackground, BioFullWidthHeader } from '../components/shared'
import Modal from 'react-modal'
import { Layout } from '../components/layout'
import { getPageBuilderComponents } from '../components/base/PageBuilderComponents'
import { getLocaleMarket } from '../utils/getLocaleMarket'
import { getLocale, getLocaleMarketUrl } from '../utils/localeUtils'
Modal.setAppElement('#___gatsby')

const HomeIndex = ({ data, pageContext }) => {
  if (!data) return null
  const {
    title,
    desc,
    marketCardButton,
    teaserReference,
    pageBuilder,
    previewImage
  } = data.homePage

  const myMarket = getLocaleMarket()
  const languages = { pageContext }
  const groupNotifications = data.marketNotifications?.nodes
  const locale = getLocale()
  useEffect(() => {
    const isBrowser = () => typeof window !== 'undefined'

    if (isBrowser()) {
      const isUrlToMarketPage =
        window.location.href.includes('http://localhost:8000/marktseite') ||
        window.location.href.includes('new.biomarkt.de/marktseite') ||
        window.location.href.includes('new.denns-biomarkt.at/marktseite')

      if (myMarket && isUrlToMarketPage) {
        navigate(
          getLocaleMarketUrl(
            locale,
            myMarket?.countryCode,
            marketPath(myMarket)
          )
        )
      }
    }
  }, [])

  return (
    <>
      <Layout isHomePage={true} isHeroTeaser={true} translations={languages}>
        <Seo title={title} description={desc} />
        <BioFullWidthHeader
          heroTeaserLink={createPagePath({
            item: teaserReference?.teaserContent
          })}
          isHomePage={true}
          teaserReference={teaserReference}
          previewImage={previewImage}
          marketCardButton={marketCardButton}
          market={myMarket}
          isFullWidth={true}
          button={teaserReference}
          groupNotifications={groupNotifications}
        />
        <LayoutBackground isHomePage={true}>
          {pageBuilder &&
            pageBuilder.map((item, index) => {
              const isHomePage = true
              return (
                <React.Fragment key={index}>
                  {getPageBuilderComponents(
                    item,
                    myMarket,
                    isHomePage,
                    {
                      ...data
                    },
                    pageContext
                  )}
                </React.Fragment>
              )
            })}
        </LayoutBackground>
      </Layout>
    </>
  )
}

export default HomeIndex

export const homeQuery = graphql`
  query (
    $dateToday: Date!
    $calendarWeek: Float!
    $nextWeek: Float!
    $language: String!
    $land: String!
  ) {
    sanityOfferPage {
      overviewHeadline
      overviewSubline
      overviewSublineMarket
      legalDisclaimer
    }
    marketNotifications: allSanityMarketNotification {
      nodes {
        ...GroupNotificationQuery
      }
    }
    homePage: sanityHomePage(
      i18n_lang: { eq: $language }
      isNotAvailableInCountry: { ne: true }
    ) {
      title
      subtitle
      desc
      i18n_lang
      marketCardButton {
        ...ButtonQuery
      }
      teaserReference {
        ...TeaserContentQuery
      }
      ...HomePageBuilderElements
    }
    sanityMarketOffers: allSanityOffer(
      filter: {
        marketHighlight: { eq: true }
        validfrom: { lte: $dateToday }
        validto: { gte: $dateToday }
        land: { eq: $land }
      }
      sort: { fields: [articleGroup___productGroup___order, title, brand] }
      limit: 6
    ) {
      nodes {
        ...OfferQuery
      }
    }
    sanityLunchPage {
      ...LunchPageQuery
    }
    allSanityLunchOffer(filter: { week: { eq: $calendarWeek } }) {
      nodes {
        ...LunchOfferQuery
      }
    }
    nextWeekOffer: allSanityLunchOffer(filter: { week: { eq: $nextWeek } }) {
      nodes {
        ...LunchOfferQuery
      }
    }
    allSanityBioMarketEvents {
      nodes {
        eventTeaser {
          ...EventQuery
        }
      }
    }
  }
`
